import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { userContext } from "../../context/User";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import Card from "../../components/Card";
import copy from "copy-to-clipboard";
import dynamicLinkService from "../../services/dynamicLinkService";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import logoMarioPenna from "../../assets/img/logo-mario-penna.png";
import surveyQualifiedService from "../../services/surveyQualifiedService";
import userWalletService from "../../services/userWalletService";
import userService from "../../services/userService";
import userSurveyLinkDynamicService from "../../services/userSurveyLinkDynamicService";

const Dashboard = () => {
  const { setSurvey, setSurveyLinkFromUser, user } = useContext(userContext);
  const [wallet, setWallet] = useState(null);
  const [surveys, setSurveys] = useState(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getWallet() {
      let response = await userWalletService.getUserWallet(user.email);
      setWallet(response);
    }

    if (wallet === null) {
      getWallet();
    }
  }, [user, wallet]);

  useEffect(() => {
    async function getSurveys() {
      let response = await surveyQualifiedService.getSurveyQualified(
        user.email
      );
      setSurveys(response);
    }

    if (wallet === null && user !== null) {
      getSurveys();
    }
  }, [surveys, user, wallet]);

  const handleTime = (time) => {
    let timeArray = time.split(":");
    if (timeArray[0] !== "00") {
      return `${timeArray[0]} hora`;
    } else if (timeArray[0] === "00" && timeArray[1] !== "00") {
      return `${timeArray[1]} min`;
    } else if (timeArray[0] === "00" && timeArray[1] === "00") {
      return `${timeArray[2]} seg`;
    }
  };

  const navigateWithParams = async (survey) => {
    setLoading(true);
    const userResponse = await userService.getUser(user.email);
    if (userResponse) {
      const regexValidateLinkIsAI = /https:\/\/hazo\.ai\/.*/;
      const linkSurveyIsAI = regexValidateLinkIsAI.test(survey.link_survey);

      const surveyLinkFromUser = await dynamicLinkService.createDynamicLink({
        token: `${userResponse.token}_${survey.id_survey}`,
        linkSurvey: survey.link_survey,
        survey_ia: linkSurveyIsAI
      });
      if (surveyLinkFromUser) {
        const userSurveyLinkDynamic =
          await userSurveyLinkDynamicService.createUserSurveyLinkDynamic({
            idUser: userResponse.id_user,
            linkSurvey: survey.link_survey,
            linkDynamic: surveyLinkFromUser
          });
        if (userSurveyLinkDynamic) {
          setSurveyLinkFromUser(surveyLinkFromUser);
          setSurvey(survey.link_survey);
          setLoading(false);
          navigate("/survey");
        }
      }
    }
    setLoading(false);
    setErrorMessage("Ocorreu um erro no sistema.");
  };

  if (loading) {
    return <Loading />;
  }

  if (wallet && surveys) {
    return (
      <section className="gradient-custom compDashboard">
        <div className="container py-2 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-lg-10">
              <div
                className="card bg-light text-black"
                style={{ borderRadius: 10 }}
              >
                <div className="dashboard">
                  <Header wallet={wallet} />
                  {/* <Home wallet={wallet} /> */}
                  <div className="row cards">
                    <div className="col-sm-12 col-md-10 com-xl-8 mx-auto">
                      {errorMessage && (
                        <p className="errorMessage">{errorMessage}</p>
                      )}
                      <Card>
                        <h4 className="card-title">Pesquisas Disponíveis</h4>
                        {surveys.length === 0 && (
                          <div>
                            <span>
                              Em breve teremos mais pesquisas disponíveis aqui
                              para você!{" "}
                            </span>
                            <br></br>
                            <span>
                              Vamos te notificar pelo email{" "}
                              <strong>recompensas@hazo.app</strong> ou por SMS{" "}
                              no seu telefone cadastrado{" "}
                            </span>
                            <br></br>
                            <span>Fique ligado!</span>
                          </div>
                        )}
                        {surveys.map((survey, index) => {
                          return (
                            <div
                              className="research mb-2 p-2 text-center"
                              key={index}
                            >
                              <h5>{`Pesquisa: ${
                                survey.description_survey
                              } (${handleTime(
                                survey.average_time_survey
                              )})`}</h5>
                              <div className="text-end">
                                <p>{`Recompensa para você: R$${survey.discharge}`}</p>
                                <p>{`Recompensa para doação: R$${survey.donation}`}</p>
                              </div>

                              <button
                                onClick={() => navigateWithParams(survey)}
                              >
                                Responder
                              </button>
                            </div>
                          );
                        })}
                      </Card>
                      <Card>
                        <h4 className="card-title">
                          Sua opinião também ajuda a salvar vidas:
                        </h4>
                        <p className="card-text text-start">
                          Quando você faz o resgate do PIX na sua conta a partir
                          de R$40,00, automaticamente gera uma doação do seu
                          Saldo de Doação para o{" "}
                          <strong>Instituto Mário Penna</strong>.
                        </p>
                        <p className="card-text text-start pb-4 pb-sm-0">
                          Instituto Mário Penna é responsável pelo tratamento
                          70% dos pacientes com câncer de Minas Gerais. <br />
                          <a
                            className="text-black fw-bold"
                            href="https://mariopenna.org.br/"
                            target="blank"
                          >
                            Clique Aqui
                          </a>{" "}
                          para saber mais.
                        </p>
                        <div className="img-container">
                          <img src={logoMarioPenna} alt="" />
                        </div>
                      </Card>
                      <Card>
                        <h4 className="card-title">
                          Compartilhe com seus amigos
                        </h4>
                        <p className="card-text text-start">
                          Compartilhe o link{" "}
                          <strong
                            onClick={() => copy("https://rewards.hazo.app")}
                            className="strongWithPointer"
                          >
                            rewards.hazo.app
                          </strong>{" "}
                          com seus amigos para que eles também possam ser
                          recompensados por suas opiniões.
                        </p>
                        {/* <a href="/" className="text-black-50">
                          Compartilhar
                        </a> */}
                      </Card>
                      <button
                        className="btn btn-md buttonSignOut"
                        onClick={() => authService.signOut()}
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default Dashboard;
