import Axios from "axios";

export default class cpfCheckService {
  static getCpfCheck = async (cpf) => {
    let response = null;
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/cpf_check/?cpf=${cpf}`
    )
      .then((cpf) => {
        response = cpf.data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
