import { React, useContext } from "react";
import { Link } from "react-router-dom";
import { userContext } from "../../context/User";
import hazoHeroi1 from "../../assets/img/heroi-dedinhos-branco.png";
import "./style.scss";

const Acknowledgments = () => {
  const { valuesRescueSurvey } = useContext(userContext);

  return (
    <section className="gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card text-center pb-5 px-3">
              <h2 className="mb-5 mt-3 mb-md-5 mt-md-4 pt-3">
                Uau! Ficamos muito felizes em receber sua opinião!
              </h2>
              <div className="card-body card-info my-0 mx-auto">
                <h5 className="p-3">Você acaba de Acumular:</h5>
                <div className="mb-3">
                  <p className="m-0">Recompensa para você:</p>
                  <h4 className="m-2">R${valuesRescueSurvey.discharge}</h4>
                </div>
                <div className="mt-2">
                  <p className="m-0">Recompensa para doação:</p>
                  <h4 className="m-2">R${valuesRescueSurvey.donation}</h4>
                </div>
              </div>
              <Link className="text-black-50 a-home mt-3" to="/dashboard">
                Voltar à Página Inicial
              </Link>
              <img
                className="my-5 card-img-bottom img-heroi align-self-center"
                src={hazoHeroi1}
                alt="Hazo Camaleão agradecendo"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Acknowledgments;
