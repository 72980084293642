import { Routes, Route } from "react-router-dom";
import Acknowledgments from "../pages/Acknowledgments";
import Conditions from "../pages/Conditions";
import Dashboard from "../pages/Dashboard";
import EmailValidation from "../pages/EmailValidation";
import ForgotPassword from "../pages/ForgotPassword";
import NotFound from "../pages/NotFound";
import Presentation from "../pages/Presentation";
import Profile from "../pages/Profile";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Survey from "../pages/Survey";
import Wallet from "../pages/Wallet";
import RouteWrapper from "./RouteWrapper";

export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RouteWrapper />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/survey" element={<Survey />} />
        <Route path="/emailvalidation" element={<EmailValidation />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/wallet" element={<Wallet />} />
      </Route>
      <Route path="/presentation" element={<Presentation />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/termsandconditions" element={<Conditions />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/acknowledgments" element={<Acknowledgments />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
