import "./style.scss";
import React, { useContext, useState } from "react";
import { userContext } from "../../../context/User/";
import userRewardService from "../../../services/userRewardService";
import userDonationService from "../../../services/userDonationService";
import WalletConfirmation from "../../../pages/WalletConfirmation";

const UserConfirmation = ({ setModalConfirmationData, userData }) => {
  const [confirmation, setConfirmation] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const { user } = useContext(userContext);

  const handleDonation = async () => {
    const responseDonation = await userDonationService.createUserDonation({
      user: user.email,
      value_payment_donation: userData.totalValueDonation,
      cpf: userData.cpf
    });

    if (responseDonation === "Donation created with success") {
      setConfirmation(true);
    } else {
      return setErrorStatus(
        "Não foi possível registrar sua doação, entre em contato com o suporte."
      );
    }
  };

  const handleReward = async () => {
    const responseReward = await userRewardService.createUserReward({
      user: user.email,
      pixKey: userData.pix,
      pixType: userData.keyTypePix,
      cpf: userData.cpf,
      valuePaymentReward: userData.valuePaymentReward,
      valuePaymentDonation: userData.valuePaymentDonation,
      typeRescue: userData.option
    });
    if (responseReward === "Reward created with success") {
      setConfirmation(true);
    } else {
      return setErrorStatus(
        "Não foi possível registrar seu resgate, entre em contato com o suporte."
      );
    }
  };

  if (confirmation) {
    return <WalletConfirmation option={userData.option} />;
  }

  if (userData !== undefined) {
    return (
      <div className="modal-dialog w-100">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title flex-fill">Confirmação</h5>
            <button
              onClick={() => setModalConfirmationData(false)}
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body content-info">
            {userData.option === "reward" ? (
              <>
                <p>
                  Por favor, confira se seus dados abaixo estão corretos. Caso
                  eles estejam incorretos o pix não será realizado!
                </p>
                <span className="mb-2">
                  Opção selecionada:{" "}
                  <b>
                    PIX na conta <br></br>
                  </b>
                  Tipo da chave:{" "}
                  <b>
                    {userData.keyTypePix} <br></br>
                  </b>
                  Chave PIX: <b>{userData.pix}</b>
                </span>
                <div className="mt-4 modal-footer footer">
                  <button
                    onClick={() => setModalConfirmationData(false)}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button onClick={handleReward} className="btn btn-confirmar">
                    Confirmar
                  </button>
                  {errorStatus !== null && (
                    <span className="error">{errorStatus}</span>
                  )}
                </div>
              </>
            ) : (
              <>
                <p>
                  Você selecionou a opção de <b>DOAÇÃO</b>. <br></br> Caso você
                  confirme essa informação, o{" "}
                  <b>VALOR DE R${userData.totalValueDonation}</b> na sua
                  carteira será destinado para o Instituto Mário Penna.
                </p>
                <span>
                  **Após confirmar, essa operação não poderá ser desfeita.
                </span>
                <div className="mt-4 modal-footer footer">
                  <button
                    onClick={() => setModalConfirmationData(false)}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleDonation}
                    className="btn btn-confirmar"
                  >
                    Confirmar
                  </button>
                  {errorStatus !== null && (
                    <span className="error">{errorStatus}</span>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default UserConfirmation;
