import "./style.scss";
import React, { useState, useContext, useEffect } from "react";
import { cpfMask } from "../maskCpf";
import { userContext } from "../../../context/User";
import cpfValidator from "../../CpfValidator";
import cpfCheckService from "../../../services/cpfCheckService";
import Loading from "../../../components/Loading";
import UserConfirmation from "../../Modal/UserConfirmation";
import userProfileService from "../../../services/userProfileService";

const Reward = ({ option, valuePaymentReward, valuePaymentDonation }) => {
  const { user } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState("");
  const [cpfIsRegistered, setCpfIsRegistered] = useState(null);
  const [keyTypePix, setKeyTypePix] = useState("");
  const [pix, setPix] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [userData, setUserData] = useState(null);
  const [modalConfirmationData, setModalConfirmationData] = useState(false);

  useEffect(() => {
    const isCpfRegistered = async () => {
      const responseUserProfile = await userProfileService.getUserProfile(
        user.email
      );
      const cpfIsTrue = responseUserProfile.find(
        (element) => element.type_description === "CPF"
      );
      setCpf(cpfIsTrue !== undefined ? cpfIsTrue.description : "");
      setCpfIsRegistered(cpfIsTrue !== undefined ? true : false);
    };

    if (cpfIsRegistered === null) {
      isCpfRegistered();
    }
  }, [cpfIsRegistered, user.email]);

  const checkCpf = async (cpf) => {
    const responseCpfCheck = await cpfCheckService.getCpfCheck(cpf);
    return responseCpfCheck;
  };

  const handleChecked = (value) => {
    if (value === "") {
      setPix("");
    }
    setKeyTypePix(value);
  };

  const handlePix = async (e) => {
    if (keyTypePix === "cpf") {
      setPix(cpfMask(e.target.value));
    } else {
      setPix(e.target.value);
    }
  };

  const handleCpf = async (e) => {
    setCpf(cpfMask(e.target.value));
  };

  const handleRequest = async () => {
    setLoading(true);

    const isPixCpfValid = cpfValidator(pix);

    if (pix.length < 1) {
      setLoading(false);
      return setErrorStatus("A chave pix não pode ser vazia");
    }

    if (
      keyTypePix === "phone" &&
      !pix.match(/^[1-9]{2}[9][0-9]{4}[0-9]{4}$/g)
    ) {
      setLoading(false);
      return setErrorStatus("Celular no formato inválido");
    }

    if (keyTypePix === "email") {
      const isValidEmail = new RegExp(
        //eslint-disable-next-line
        /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
      ).test(pix);

      if (!isValidEmail) {
        setLoading(false);
        return setErrorStatus("Email no formato inválido");
      }
    }

    if (keyTypePix === "cpf" && !isPixCpfValid) {
      setLoading(false);
      return setErrorStatus("CPF inválido");
    }

    if (!cpfIsRegistered) {
      const isCpfValid = cpfValidator(cpf);

      if (!isCpfValid) {
        setLoading(false);
        return setErrorStatus("CPF inválido");
      }
    }

    if (keyTypePix === "random" && pix.length < 36) {
      setLoading(false);
      return setErrorStatus("Chave Pix no formato inválido");
    }

    // Check cpfs exists
    if (!cpfIsRegistered) {
      if (
        typeof (await checkCpf(cpf)) === "string" &&
        (await checkCpf(cpf)) === "Cpf was been registered"
      ) {
        setLoading(false);
        return setErrorStatus("CPF já foi cadastrado");
      } else if ((await checkCpf(cpf)) === false) {
        setLoading(false);
        return setErrorStatus("Error com a API do sistema");
      }
    }

    setErrorStatus(null);
    setUserData({
      pix,
      keyTypePix,
      cpf,
      valuePaymentReward,
      valuePaymentDonation,
      option
    });
    setLoading(false);
    setModalConfirmationData(true);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <section>
        <div className="comp-reward">
          <p>
            Ao escolher essa opção o valor total do seu saldo de recompensa será
            depositado na sua conta e o saldo de doação será direcionado para a
            Instituição Mário Penna.
          </p>
          <div className="mb-3">
            <h4 className="pt-3">Informe seus dados abaixo:</h4>
            <div className="d-flex">
              <select
                onChange={(e) => {
                  handleChecked(e.target.value);
                  setPix("");
                }}
                type="text"
                className="form-control me-2"
              >
                <option value="">Tipo da Chave PIX</option>
                <option value="cpf">CPF</option>
                <option value="phone">Celular</option>
                <option value="email">Email</option>
                <option value="random">Chave Aleatória</option>
              </select>
              <input
                value={keyTypePix === "" ? "" : pix}
                onChange={handlePix}
                type={
                  keyTypePix === "random" ||
                  keyTypePix === "email" ||
                  keyTypePix === "cpf"
                    ? "text"
                    : "number"
                }
                disabled={keyTypePix === ""}
                className="form-control"
                placeholder="Digite sua chave pix"
              />
            </div>
            {!cpfIsRegistered && (
              <label className="label-area">
                Antes de enviar seu PIX, cadastre seu CPF:
                <input
                  value={cpf}
                  onChange={(e) => handleCpf(e)}
                  className="form-control"
                  placeholder="ex: 123.456.789-10"
                />
              </label>
            )}
          </div>
          {errorStatus !== null && <span className="error">{errorStatus}</span>}
          <button className="btn btn-resgate" onClick={handleRequest}>
            Resgatar
          </button>
          {modalConfirmationData && loading === false && (
            <div className="modal modal-container ">
              <UserConfirmation
                userData={userData}
                setModalConfirmationData={setModalConfirmationData}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
};

export default Reward;
