import "./style.scss";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userContext } from "../../context/User";
import surveyCompletedService from "../../services/surveyCompletedService";
import surveyService from "../../services/surveyService";

function Survey() {
  const { setValuesRescueSurvey, survey, surveyLinkFromUser, user } =
    useContext(userContext);
  const navigator = useNavigate();

  useEffect(() => {
    const createdJourneySurvey = async () => {
      const responseSurveyCompleted =
        await surveyCompletedService.updateSurveyCompleted({
          user: user.email,
          linkSurvey: surveyLinkFromUser
        });

      if (responseSurveyCompleted) {
        const responseSurvey = await surveyService.getSurvey(survey);
        setValuesRescueSurvey({
          discharge: responseSurvey.discharge,
          donation: responseSurvey.donation
        });

        if (responseSurvey.discharge > 0) {
          navigator("/Acknowledgments");
        } else {
          navigator("/dashboard");
        }
      }
    };

    window.addEventListener("message", (event) => {
      if (
        (event.origin === process.env.REACT_APP_POST_MESSAGE_HAZO_ME &&
          event.data === "Survey has ended") ||
        (event.origin === process.env.REACT_APP_POST_MESSAGE_HAZO_AI &&
          event.data === "Survey has ended AI")
      ) {
        createdJourneySurvey();
      }
    });
    // eslint-disable-next-line
  }, []);

  if (surveyLinkFromUser !== null) {
    return (
      <div className="compSurvey">
        <iframe id="frame" src={surveyLinkFromUser} title="survey"></iframe>
      </div>
    );
  } else {
    return (
      <div>
        <span>Carregando...</span>
      </div>
    );
  }
}

export default Survey;
