import "./style.scss";
import React from "react";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { userContext } from "../../context/User";
import { useNavigate, useLocation } from "react-router-dom";
import authService from "../../services/authService";
import emailMktService from "../../services/emailMarketingService";
import hazoHeroi1 from "../../assets/img/heroi-com-fundo.png";
import userService from "../../services/userService";

const EmailValidation = () => {
  const location = useLocation();
  const { user } = useContext(userContext);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(false);
  const [errorStatus, setErrorStatus] = React.useState(null);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      if (user.emailVerified) {
        const responseUpdateUser = await userService.updateUser({
          user: user.email,
          email_verified: "True"
        });
        if (responseUpdateUser.data === "User was updated.") {
          emailMktService.sendEmailMarketingWelcome(user.email);
          navigate("/dashboard");
        } else if (
          responseUpdateUser.data === "User has already been updated."
        ) {
          navigate("/dashboard");
        } else if (
          responseUpdateUser.data === "An error from database happend."
        ) {
          setErrorStatus("Ocorreu um erro, recarregue a página novamente.");
        } else {
          setErrorStatus("Um erro aconteceu, tente novamente mais tarde.");
        }
      }
      setLoading(false);
    };

    if (loading) {
      verifyUser();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!message) return;
    let myInterval = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [message, timer]);

  function freeze(el) {
    setMessage(true);
    authService.sendEmailVerification();
    el.disabled = true;
    setTimeout(() => {
      el.disabled = false;
      setMessage(false);
      setTimer(30);
    }, 30000);
  }

  if (loading) {
    return null;
  } else {
    return (
      <section className="compEmailValidation gradient-custom d-flex flex-column justify-content-center">
        <div className="row d-flex justify-content-center align-items-center h-100 px-3">
          <div className="col-12 col-md-8 col-ls-6 col-xl-5">
            <div
              className="card bg-light text-black"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body px-3 p-sm-5 text-center">
                {location?.state?.resendEmailValidation ? (
                  <div>
                    <p>
                     Enviamos um link de validação do <strong>Hazo Rewards</strong> para o seu email: {user.email}
                    </p>
                    <p>
                      Por favor, confira também a <strong>caixa de spam</strong> caso não encontre o email em sua caixa de entrada.
                    </p>
                  {message && (
                    <span className="d-flex justify-content-center text-success">
                    Email Reenviado
                    </span>
                  )}
                  {errorStatus !== null && <p className="error">{errorStatus}</p>}
                  <button
                    className="btn btn-lg buttonSendEmail"
                    onClick={(e) => freeze(e.target)}
                  >
                    Enviar Novamente{" "}
                    {message && (timer < 10 ? `(00:0${timer})` : `(00:${timer})`)}
                  </button>
                  <p className="mt-2 mb-0 fs-sm-6">
                    <Link to="/login" className="text-black">
                      Página Inicial
                    </Link>
                  </p>
                  </div>
                ) :
                (<div>
                  <h2 className="mb-2">
                  Pronto!
                  <span
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      justifyContent: "center",
                      fontSize: 22
                    }}
                  >
                    Agora é só conferir seu email para ativar sua conta
                  </span>
                </h2>
                <img
                  className="card-img-top img-heroi m-4"
                  src={hazoHeroi1}
                  alt="Hazo heroi 1"
                />
                <p className="text-black-50 mb-1 mb-sm-4">
                  Enviamos um link de validação do <strong>Hazo Rewards</strong> para o seu email: {user.email}
                </p>
                <p className="text-black-50 mb-3 mb-sm-4">
                  Por favor, confira também a <strong>caixa de spam</strong> caso não encontre o email em sua caixa de entrada.
                </p>
                {message && (
                  <span className="d-flex justify-content-center text-success">
                    Email Reenviado
                  </span>
                )}
                {errorStatus !== null && <p className="error">{errorStatus}</p>}
                <button
                  className="btn btn-lg buttonSendEmail"
                  onClick={(e) => freeze(e.target)}
                >
                  Enviar Novamente{" "}
                  {message && (timer < 10 ? `(00:0${timer})` : `(00:${timer})`)}
                </button>
                <p className="mt-2 mb-0 fs-sm-6">
                  <Link to="/login" className="text-black">
                    Página Inicial
                  </Link>
                </p>
                </div>
                )
              }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default EmailValidation;
