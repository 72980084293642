import "./style.scss";
import React from "react";
import Donation from "./Donation";
import Reward from "./Reward";

const RescueSection = ({
  option,
  valuePaymentReward,
  valuePaymentDonation
}) => {
  return (
    <section>
      {option === "reward" ? (
        <Reward
          option={option}
          valuePaymentReward={valuePaymentReward}
          valuePaymentDonation={valuePaymentDonation}
        />
      ) : (
        <Donation
          option={option}
          valuePaymentReward={valuePaymentReward}
          valuePaymentDonation={valuePaymentDonation}
        />
      )}
    </section>
  );
};

export default RescueSection;
