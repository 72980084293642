import React from "react";
import { useNavigate } from "react-router-dom";
import hazoHeroi2 from "../../assets/img/heroi-normal-com-fundo.png";
import "./style.scss";
// import { FaArrowLeft, FaBars } from "react-icons/fa";

const Header = (props) => {
  const { wallet } = props;
  const navigator = useNavigate();

  return (
    <nav
      className="compHeader navbar navbar-light bg-light col-sm-12 col-md-10 com-xl-8 mx-auto"
      style={{ borderRadius: 10 }}
    >
      <div className="container justify-content-center">
        {/* <button onClick={handleRoute}>
          {location.pathname === "/dashboard" ? <FaBars /> : <FaArrowLeft />}
        </button> */}
        <h1 className="logo-header">Hazo Rewards</h1>
      </div>
      <div className="card-body text-center">
        <div className="col-sm-12 com-xl-8">
          <section className="wallet d-flex flex-row justify-content-center align-items-center">
            <div className="money">
              <h2>Saldo Recompensa</h2>
              <h3>R$ {wallet.value_discharge}</h3>
              <h4>Saldo Doação</h4>
              <h5>R$ {wallet.value_donation}</h5>
            </div>
            <div className="btn-container">
              <img className="img-heroi" src={hazoHeroi2} alt="Hazo Heroi 2" />
              {wallet.value_discharge >= 40 ? (
                <button
                  className="btn btn-md btn-resgate enabled"
                  onClick={() => navigator("/wallet")}
                >
                  Resgatar Pix
                </button>
              ) : (
                <button className="btn btn-md btn-resgate" disabled>
                  Resgatar Pix
                </button>
              )}
              <span>A partir de R$40,00</span>
            </div>
          </section>
        </div>
      </div>
    </nav>
  );
};

export default Header;
