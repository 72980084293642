import Axios from "axios";

export default class userWalletService {
  static createUserWallet = async (email) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user_wallet/`,
      data: JSON.stringify({
        user: email
      })
    })
      .then(() => {
        response = true;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getUserWallet = async (email) => {
    let response = null;
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user_wallet/?user=${email}`
    )
      .then((wallet) => {
        response = wallet.data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
