import Axios from "axios";

export default class surveyService {
  static getSurvey = async (survey) => {
    let response = null;
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/survey/?survey=${survey}`
    )
      .then((survey) => {
        response = survey.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
