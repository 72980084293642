import "./style.scss";
import React, { useState } from "react";
import { cpfMask } from "../maskCpf";
import cpfValidator from "../../CpfValidator";
import Loading from "../../../components/Loading";
import UserConfirmation from "../../Modal/UserConfirmation";

const Donation = ({ option, valuePaymentDonation, valuePaymentReward }) => {
  const [cpf, setCpf] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalConfirmationData, setModalConfirmationData] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleRequest = async () => {
    setLoading(true);
    setErrorStatus(null);

    const isCpfValid = cpfValidator(cpf);

    if (!isCpfValid) {
      setLoading(false);
      return setErrorStatus("CPF inválido");
    }

    const totalValueDonation = parseFloat(
      parseFloat(valuePaymentReward) + parseFloat(valuePaymentDonation)
    ).toFixed(2);

    setUserData({ option, totalValueDonation, cpf });
    setLoading(false);
    setModalConfirmationData(true);
  };

  const handleCpf = async (e) => {
    setCpf(cpfMask(e.target.value));
  };

  if (loading) {
    <Loading />;
  } else {
    return (
      <section className="comp-donation">
        <p>
          Ao escolher essa opção o valor total do seu
          <b
            style={{
              textTransform: "uppercase",
              fontWeight: 600
            }}
          >
            {" "}
            saldo de recompensa mais o saldo de doação
          </b>{" "}
          serão direcionados para a Instituição Mário Penna
        </p>
        <label className="label-area">
          Insira seu CPF para realizar a doação:
          <input
            value={cpf}
            onChange={(e) => handleCpf(e)}
            className="form-control"
            placeholder="ex: 123.456.789-10"
          />
        </label>
        {errorStatus !== null && <span className="error">{errorStatus}</span>}
        <button className="btn btn-resgate" onClick={handleRequest}>
          Doar
        </button>
        {modalConfirmationData && (
          <div className="modal modal-container ">
            <UserConfirmation
              userData={userData}
              setModalConfirmationData={setModalConfirmationData}
            />
          </div>
        )}
      </section>
    );
  }
  setUserData({ option, valuePaymentReward, valuePaymentDonation });
  setModalConfirmationData(true);
};

export default Donation;
