import React from "react";
import Confetti from "react-confetti";
import hazoHeroi1 from "../../assets/img/heroi-com-fundo.png";
import { Link } from "react-router-dom";

const WalletConfirmation = ({ option }) => {
  return (
    <section
      style={{ height: "100%", minWidth: "100%" }}
      className="section-confetti gradient-custom d-flex flex-column justify-content-center"
    >
      <Confetti
        numberOfPieces={100}
        width={window.screen.width}
        height={window.screen.height}
        opacity={0.7}
      />
      <div className="row d-flex justify-content-center align-items-center h-100 px-3">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div
            className="card bg-light text-black"
            style={{ borderRadius: "1rem" }}
          >
            <div className="confetti">
              <div className="card-body px-3 py-5 px-sm-5 text-center">
                <h2 className="mb-2">
                  {option === "donation"
                    ? "Doação Realizada!"
                    : "Solicitação Aceita!"}
                </h2>
                <div className="modal-body">
                  {option === "donation" ? (
                    <p className="fs-5">
                      Obrigado por contribuir com a instituição. Ficamos muito
                      felizes com a sua escolha.
                    </p>
                  ) : (
                    <p className="fs-5">
                      Agora vamos conferir seus dados e se tudo estiver certo,
                      em até <strong>3 dias úteis</strong> será efetuado o PIX
                      na sua conta!
                    </p>
                  )}
                </div>
                <img
                  className="card-img-bottom img-heroi m-4"
                  src={hazoHeroi1}
                  alt="camaleão hazo"
                />
                <Link to="/dashboard" className="text-black d-block">
                  Voltar ao menu inicial
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WalletConfirmation;
