import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Auth } from "../services/authService.jsx";
import { userContext } from "../context/User/index.jsx";

export default function RouteWrapper() {
  const { setUser, user } = useContext(userContext);
  const location = useLocation();
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    Auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        setUser({
          email: userAuth.email,
          emailVerified: userAuth.emailVerified
        });
        setLoadingPage(false);
      } else {
        setUser(null);
        setLoadingPage(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  if (loadingPage) {
    return (
      <div>
        <span>Carregando...</span>
      </div>
    );
  } else {
    if (user) {
      if (user.emailVerified === false) {
        return location.pathname === "/emailvalidation" ? (
          <Outlet />
        ) : (
          <Navigate to="/emailvalidation" />
        );
      } else {
        return location.pathname === "/" ? (
          <Navigate to="/dashboard" />
        ) : (
          <Outlet />
        );
      }
    } else {
      return <Navigate to="/presentation" />;
    }
  }
}
