import React from "react";
import { Link } from "react-router-dom";
import hazoNormal from "../../assets/img/hazo-normal.png";

const Profile = () => {
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="card-body p-5 text-center">
      <div className="mb-md-5 mt-md-4 pb-3">
        <h2 className="mb-2">Recuperação de senha</h2>
        <img
          className="card-img-top img-hazo my-5 pb-2"
          src={hazoNormal}
          alt="camaleão hazo"
        />
        <div className="form-outline form-black mb-4">
          <input
            type="email"
            placeholder="email existente"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control form-control-lg"
          />
        </div>
        <div className="form-outline form-black mb-4">
          <input
            type="number"
            placeholder="telefone já existente"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="form-control form-control-lg"
          />
        </div>
        <fieldset disabled>
          <div className="form-outline form-black mb-4">
            <input
              value={"profissão"}
              className="form-control form-control-lg"
            />
          </div>
          <div className="input-desabled mb-4">
            <input
              value={"email usuário"}
              className="form-control form-control-lg"
            />
          </div>
        </fieldset>
        <button onClick={handleSubmit} className="btn btn-send btn-lg px-5">
          Enviar
        </button>
        <p className="mt-4 mb-0 fs-sm-6">
          Para outras alterações entre em
          <Link to="/login" className="text-black-50">
            {" "}
            contato
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Profile;
