import { createContext, useState } from "react";

export const userContext = createContext({});

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [surveyLinkFromUser, setSurveyLinkFromUser] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [valuesRescueSurvey, setValuesRescueSurvey] = useState({
    discharge: "0.00",
    donation: "0.00"
  });

  return (
    <userContext.Provider
      value={{
        setSurvey,
        setSurveyLinkFromUser,
        setUser,
        setValuesRescueSurvey,
        survey,
        surveyLinkFromUser,
        user,
        valuesRescueSurvey
      }}
    >
      {children}
    </userContext.Provider>
  );
}
