import React from "react";
import hazoScare from "../../assets/img/hazo-scare.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <section className="gradient-custom d-flex flex-column justify-content-center">
      <div className="row d-flex justify-content-center align-items-center h-100 px-3">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div
            className="card bg-light text-black"
            style={{ borderRadius: "1rem" }}
          >
            <div className="card-body p-5 text-center">
              <h2 className="mb-2">Você seperdeu?</h2>
              <img
                className="card-img-top img-hazo m-5"
                src={hazoScare}
                alt="camaleão hazo"
              />
              <p className="text-black-50 mb-2 mb-sm-0">
                Infelizmente não encontramos essa página.
              </p>
              <p className="text-black-50 mb-4">
                Por favor, clique no link abaixo para voltar ao início.
              </p>
              <p className="mb-0 fs-sm-6">
                <Link to="/" className="text-black">
                  Página Inicial
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
