import Axios from "axios";

export default class emailMarketingService {
  static sendEmailMarketingWelcome = async (email) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_EMAIL_TRANSACTIONAL}messages/send-template`,
      data: JSON.stringify({
        key: process.env.REACT_APP_KEY_API_EMAIL_TRANSACTIONAL,
        template_name: "hazo-rewards-welcome",
        template_content: {},
        message: {
          track_opens: "true",
          tag: "hazo-rewards-welcome",
          subaccount: "hazo-rewards",
          to: [
            {
              email: email,
              type: "to"
            }
          ]
        }
      })
    })
      .then((res) => {
        response = res;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
