import Axios from "axios";

export default class userSurveyLinkDynamicService {
  static createUserSurveyLinkDynamic = async (data) => {
    let response = null;
    let dataToRequest = JSON.stringify(data);
    await Axios.post(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user_survey_link_dynamic/`,
      dataToRequest
    )
      .then((dynamicLink) => {
        if (
          dynamicLink.data.data === "User dynamic link already exists." ||
          dynamicLink.data.data === "User dynamic link was created."
        ) {
          response = true;
        }
      })
      .catch(() => {
        response = false;
      });
    return response;
  };
}
