import Axios from "axios";

export default class userDonationService {
  static createUserDonation = async (payload) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user_donation/`,
      data: JSON.stringify(payload)
    })
      .then((res) => {
        response = res.data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
