import React from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/authService";
import hazoHeroi2 from "../../assets/img/heroi-normal-com-fundo.png";

const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [showButton, setShowButton] = React.useState(true);

  const handleEnter = (e) => {
    if (e === "Enter") return handleSubmit();
  };

  const handleSubmit = async () => {
    if (email === "") {
      return setMessage("Campo de email é obrigatório");
    }

    const isValidEmail = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
    ).test(email);

    if (isValidEmail) {
      const responseResetPassword = await AuthService.sendEmailToResetPassword(email);

      if(responseResetPassword.code === "success") {
        setMessage("sucesso");
        return;
      } else if (responseResetPassword.code === "auth/user-not-found") {
        setShowButton(false);
        setMessage(responseResetPassword.code);
        return;
      } else if (responseResetPassword.code === "auth/too-many-requests") {
        setMessage(responseResetPassword.code);
        return;
      }
    } else {
      setMessage("Digite um email no formato válido");
    }
  };

  return (
    <section className="gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card bg-light text-black"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 text-center">
                <div className="mb-md-5 mt-md-4 pb-3">
                  <h2 className="mb-2">Recuperação de senha</h2>
                  <img
                    className="card-img-top img-heroi"
                    src={hazoHeroi2}
                    alt="camaleão hazo"
                  />
                  <p className="text-black-50 mb-5">
                    Por favor, informe seu email!
                  </p>
                  <div className="form-outline form-black mb-4">
                    <input
                      type="email"
                      id="typeEmailX"
                      placeholder="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={(e) => handleEnter(e.key)}
                      className="form-control form-control-lg"
                    />
                  </div>
                  {message === "sucesso" && (
                    <strong>
                      <p style={{ color: "green" }}>
                      Enviamos um email de redefinição de senha para seu email! Agora é so checar sua caixa de entrada e clicar no email para redefinir sua senha
                      </p>
                    </strong>
                  )}
                  {message === "auth/user-not-found" && (
                    <>
                      <p style={{ color: "red" }}>Ops! Não localizamos o email em nossa plataforma. Clique abaixo para fazer o cadastro.</p>
                      <p className="mt-1 mb-5 fs-sm-6">
                        <Link to="/signup" style={{ color: "black" }}>
                          Cadastrar agora!
                        </Link>
                      </p>
                    </>
                  )}
                  {message === "auth/too-many-requests" && (
                    <p style={{ color: "red" }}>Você fez muitas tentativas, tente novamente em alguns minutos!</p>
                  )}
                  {showButton  && (
                    <button
                      onClick={handleSubmit}
                      className="btn btn-send btn-lg px-5"
                    >
                    Enviar
                    </button>
                  )}
                  <p className="mt-4 mb-0 fs-sm-6">
                    <Link to="/login" style={{ color: "black" }}>
                      Voltar para login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
