import React from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import EyeVisible from "../../assets/img/ICONEOLHOHAZO.png";
import EyeInvisible from "../../assets/img/ICONEOLHOHAZOFECHADO.png";
import filterByState from "../../components/Cities";
import hazoHeroi2 from "../../assets/img/heroi-normal-com-fundo.png";
import Loading from "../../components/Loading";
import Select from "react-select";
import surveyCompletedService from "../../services/surveyCompletedService";
import userProfileService from "../../services/userProfileService";
import userService from "../../services/userService";
import userSurveyLinkDynamicService from "../../services/userSurveyLinkDynamicService";
import userWalletService from "../../services/userWalletService";

const SignUp = () => {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birth, setBirth] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailRepeat, setEmailRepeat] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [cpf, setCPF] = React.useState("");
  const [ocupation, setOcupation] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [cities, setCities] = React.useState([]);
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");
  //eslint-disable-next-line
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const age = calculateAge(birth);
    const dateNow = new Date();
    const dateMinForRegistration = dateNow.getFullYear() - 16;
    const dateMaxForRegistration = dateNow.getFullYear() - 100;
    const isValidEmail = new RegExp(
      //eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
    ).test(email);

    if (
      !email ||
      !emailRepeat ||
      !password ||
      !passwordRepeat ||
      !name ||
      !lastName ||
      !gender ||
      !birth ||
      !cpf ||
      !phone ||
      !ocupation ||
      !state ||
      !city
    ) {
      setLoading(false);
      return setErrorStatus("Você precisa preencher todos os campos");
    } else {
      setErrorStatus("");
    }

    if (name.length < 3) {
      setLoading(false);
      return setErrorStatus("Por favor digite seu nome");
    }

    if (lastName.length < 3) {
      setLoading(false);
      return setErrorStatus("Por favor digite seu sobrenome completo");
    }

    if (age < 16 || age > 100) {
      setLoading(false);
      return setErrorStatus(
        `Por favor digite uma data de nascimento entre ${dateMinForRegistration} e ${dateMaxForRegistration}`
      );
    }

    if (gender === "Gênero") {
      setLoading(false);
      return setErrorStatus("Por favor selecione um gênero");
    }

    if (ocupation === "Profissão / Área de Atuação") {
      setLoading(false);
      return setErrorStatus(
        "Por favor selecione uma Profissão / Área de Atuação"
      );
    }

    if (state === "Estado") {
      setLoading(false);
      return setErrorStatus("Por favor selecione estado");
    }

    if (city === "") {
      setLoading(false);
      return setErrorStatus("Por favor selecione uma cidade");
    }

    if (!isValidEmail) {
      setLoading(false);
      return setErrorStatus("Email no formato inválido");
    }

    if (email !== emailRepeat) {
      setLoading(false);
      return setErrorStatus("Emails não conferem");
    }

    if (password !== passwordRepeat) {
      setLoading(false);
      return setErrorStatus("Senhas não conferem");
    }

    if (password.length < 6) {
      setLoading(false);
      return setErrorStatus("A senha precisa ser maior que 6 caracteres");
    }

    if (!phone.match(/^[1-9]{2}[9][0-9]{4}[0-9]{4}$/g)) {
      setLoading(false);
      return setErrorStatus("Telefone no formato inválido");
    }

    if (!validCPF(cpf)) {
      setLoading(false);
      return setErrorStatus("CPF no formato inválido");
    }

    // Controls variables
    let controlCreateUser = null;
    let controlCreateUserProfile = null;
    let controlCreateUserWallet = null;
    let controlCreateUserSurveyLinkDynamic = null;
    let controlCreateUpdateSurveyCompleted = null;

    // Create user on firebase
    let responseSignUpFireBase = await authService.signUp({ email, password });
    if (responseSignUpFireBase.response === true) {
      // Create user on database MySql
      let responseCreateUserOnMySql = await userService.createUser(email);

      if (responseCreateUserOnMySql.response === true) {
        controlCreateUser = true;
      } // Delete user on Firebase case error in create user on MySql
      else if (responseCreateUserOnMySql.errorMessage !== null) {
        let responseDeleteUserFromFirebase =
          await authService.deleteUserWithoutLogin(email, password);

        if (responseDeleteUserFromFirebase.response === true) {
          // Reset password for user before next submit
          setPasswordRepeat("");
          setPassword("");
          setLoading(false);

          // Message to user try signup again becouse error from MySql
          return setErrorStatus(
            "Ocorreu um erro durante seu cadastro. Confira seus dados e tente novamente!"
          );
        } else if (responseDeleteUserFromFirebase.errorMessage !== null) {
          setLoading(false);
          return setErrorStatus(
            "Falha ao realizar seu cadastro. Entre em contato o suporte!"
          );
        }
      }
    } else if (responseSignUpFireBase.errorMessage !== null) {
      setLoading(false);
      return setErrorStatus(responseSignUpFireBase.errorMessage);
    }

    // Create user profile
    if (controlCreateUser) {
      let nameAndLastName = name + " " + lastName;

      let responseUserProfile = await userProfileService.createUserProfile({
        email: email,
        arrayDescription: [
          { typeDescription: "phone", description: phone },
          { typeDescription: "CPF", description: cpf },
          { typeDescription: "ocupation", description: ocupation },
          { typeDescription: "state", description: state },
          { typeDescription: "city", description: city.value },
          { typeDescription: "name", description: nameAndLastName },
          { typeDescription: "birth", description: birth },
          { typeDescription: "gender", description: gender }
        ]
      });
      if (responseUserProfile === true) {
        controlCreateUserProfile = true;
      } else if (!responseUserProfile) {
        setLoading(false);
        return setErrorStatus(
          "Nao foi possível criar o perfil do usuário, entre em contato com o nosso suporte!"
        );
      }
    }

    // Create user wallet
    if (controlCreateUserProfile) {
      let responseUserWallet = await userWalletService.createUserWallet(email);
      if (responseUserWallet) {
        controlCreateUserWallet = true;
      } else if (!responseUserWallet) {
        setLoading(false);
        return setErrorStatus(
          "Nao foi possível criar sua carteira, entre em contato com o nosso suporte!"
        );
      }
    }

    // Create user survey link dynamic
    if (controlCreateUserWallet) {
      let responseGetUser = await userService.getUser(email);
      let responseUserSurveyLinkDynamic =
        await userSurveyLinkDynamicService.createUserSurveyLinkDynamic({
          idUser: responseGetUser.id_user,
          linkSurvey: "https://hazorewards.hazo.app"
        });
      if (responseUserSurveyLinkDynamic) {
        controlCreateUserSurveyLinkDynamic = true;
      } else if (!responseUserSurveyLinkDynamic) {
        setLoading(false);
        return setErrorStatus(
          "Nao foi possível criar sua primeira pesquisa, entre em contato com o nosso suporte!"
        );
      }
    }

    // Update first user survey
    if (controlCreateUserSurveyLinkDynamic) {
      let responseSurveyCompleted =
        await surveyCompletedService.updateSurveyCompleted({
          user: email,
          linkSurvey: "https://hazorewards.hazo.app",
          linkDynamic: null
        });

      if (responseSurveyCompleted) {
        controlCreateUpdateSurveyCompleted = true;
      } else if (!responseSurveyCompleted) {
        setLoading(false);
        return setErrorStatus(
          "Nao foi possível atualizar sua pesquisa, entre em contato com o nosso suporte!"
        );
      }
    }

    if (controlCreateUpdateSurveyCompleted) {
      authService.sendEmailVerification();

      setLoading(false);
      navigate("/emailvalidation");
    }
  }

  const calculateAge = (birthDate) => {
    // 365.25 days (0.25 because of leap years) which are 3.15576e+10 milliseconds (365.25 * 24 * 60 * 60 * 1000) respectively.
    return Math.floor(
      (new Date() - new Date(birthDate).getTime()) / 3.15576e10
    );
  };

  const handleSelectState = (state) => {
    const cities = filterByState(state).map((city) => ({
      value: city,
      label: city
    }));
    setState(state);
    setCities(cities);
  };

  const handleFormatCpf = (cpfInputValue) => {
    let value = cpfInputValue;
    value = value.replace(/\D/g, "");
    if (value.length > 0 && value.length < 4) {
      value = value.substring(0, 3);
    } else if (value.length > 3 && value.length < 7) {
      value = value.substring(0, 3) + "." + value.substring(3, value.length);
    } else if (value.length > 6 && value.length < 10) {
      value =
        value.substring(0, 3) +
        "." +
        value.substring(3, 6) +
        "." +
        value.substring(6, value.length);
    } else if (value.length > 9 && value.length < 12) {
      value =
        value.substring(0, 3) +
        "." +
        value.substring(3, 6) +
        "." +
        value.substring(6, 9) +
        "-" +
        value.substring(9, value.length);
    } else {
      value =
        value.substring(0, 3) +
        "." +
        value.substring(3, 6) +
        "." +
        value.substring(6, 9) +
        "-" +
        value.substring(9, 11);
    }
    setCPF(value);
  };

  function validCPF(cpfToValid) {
    cpfToValid = cpfToValid.replace(/[^\d]+/g, "");
    if (cpfToValid === "") return false;

    // Elimina CPFs invalidos conhecidos
    if (
      cpfToValid.length !== 11 ||
      cpfToValid === "00000000000" ||
      cpfToValid === "11111111111" ||
      cpfToValid === "22222222222" ||
      cpfToValid === "33333333333" ||
      cpfToValid === "44444444444" ||
      cpfToValid === "55555555555" ||
      cpfToValid === "66666666666" ||
      cpfToValid === "77777777777" ||
      cpfToValid === "88888888888" ||
      cpfToValid === "99999999999"
    )
      return false;

    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpfToValid.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpfToValid.charAt(9))) {
      return false;
    }

    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpfToValid.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpfToValid.charAt(10))) {
      return false;
    }
    return true;
  }

  return (
    <section className="gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card bg-light text-black"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 text-center">
                <div className="mb-md-4 mt-md-4">
                  <h2 className="mb-2">Cadastro</h2>
                  <img
                    className="card-img-top img-heroi mb-4"
                    src={hazoHeroi2}
                    alt="camaleão hazo"
                  />
                  {loading ? (
                    <Loading />
                  ) : (
                    <form className="form" onSubmit={handleSubmit}>
                      <div className="form-outline form-black mb-4">
                        <input
                          type="text"
                          id="nameRegister"
                          placeholder="nome"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="form-control form-control-lg mb-4"
                        />
                        <input
                          type="text"
                          id="lastNameRegister"
                          placeholder="Sobrenome"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          className="form-control form-control-lg"
                        />
                      </div>
                      <div className="form-outline form-black mb-4">
                        <label
                          className="text-start"
                          style={{ width: "100%", fontSize: 16 }}
                        >
                          {" "}
                          Data de nascimento:
                          <input
                            type="date"
                            id="birthRegister"
                            value={birth}
                            onChange={(e) => setBirth(e.target.value)}
                            className="form-control form-control-lg"
                          />
                        </label>
                      </div>
                      <div className="form-outline form-black mb-4">
                        <select
                          id="genderRegister"
                          className="form-select"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option defaultValue={""}>Gênero</option>
                          <option value="feminino">Feminino</option>
                          <option value="masculino">Masculino</option>
                          <option value="n-binário">Não Binário</option>
                        </select>
                      </div>
                      <div className="form-outline form-black mb-4">
                        <input
                          type="email"
                          id="typeEmailX"
                          placeholder="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control form-control-lg"
                        />
                      </div>
                      <div className="form-outline form-black mb-4">
                        <input
                          type="email"
                          id="typeEmailAgainX"
                          placeholder="Repetir email"
                          value={emailRepeat}
                          onChange={(e) => setEmailRepeat(e.target.value)}
                          className="form-control form-control-lg"
                        />
                      </div>
                      <div className="form-outline form-black mb-4">
                        <label
                          className="text-start"
                          style={{ width: "100%", fontSize: 16 }}
                        >
                          {" "}
                          Telefone Celular com DDD:
                          <input
                            type="number"
                            id="typePhoneX"
                            placeholder="Ex: 11987654321"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="form-control form-control-lg"
                          />
                        </label>
                      </div>
                      <div className="form-outline form-black mb-4">
                        <label
                          className="text-start"
                          style={{ width: "100%", fontSize: 16 }}
                        >
                          {" "}
                          CPF:
                          <input
                            type="text"
                            placeholder="Ex: 123.456.789-10"
                            value={cpf}
                            onChange={(e) => handleFormatCpf(e.target.value)}
                            className="form-control form-control-lg"
                          />
                        </label>
                      </div>
                      <div className="form-outline form-black mb-4">
                        <select
                          id="ocupationRegister"
                          className="form-select"
                          value={ocupation}
                          onChange={(e) => setOcupation(e.target.value)}
                        >
                          <option defaultValue={""}>
                            Profissão / Área de Atuação
                          </option>
                          <option value="Adm financeiro contábil">
                            Administrativo/Financeiro/Contábil
                          </option>
                          <option value="Agro">Agronegócio</option>
                          <option value="Aposentado">Aposentado</option>
                          <option value="Autônomo">Autônomo</option>
                          <option value="Comércio">Comércio</option>
                          <option value="Comunicação publicidade">
                            Comunicação/publicidade
                          </option>
                          <option value="Desempregado">Desempregado</option>
                          <option value="Professor">
                            Educação - Professor
                          </option>
                          <option value="Educação">Educação - Outra</option>
                          <option value="Empresário">Empresário</option>
                          <option value="Engenharia">Engenharia</option>
                          <option value="Financeira/Contábil">
                            Financeira/Contábil
                          </option>
                          <option value="Gestão Pública">Gestão Pública</option>
                          <option value="RH">Recursos Humanos</option>
                          <option value="Enfermeira">Saúde - Enfermeira</option>
                          <option value="Médico">Saúde - Médico</option>
                          <option value="Saúde">Saúde - Outra</option>
                          <option value="TI">Tecnologia (TI)</option>
                          <option value="Vendas/Marketing">
                            Vendas/Marketing
                          </option>
                          <option value="Outra">Outra</option>
                        </select>
                      </div>
                      <div className="form-outline form-black mb-4 state-select">
                        <select
                          id="state"
                          className="form-select"
                          value={state}
                          onChange={(e) => handleSelectState(e.target.value)}
                        >
                          <option defaultValue={""}>Estado</option>
                          <option value="AC">Acre (AC)</option>
                          <option value="AL">Alagoas (AL)</option>
                          <option value="AP">Amapá (AP)</option>
                          <option value="AM">Amazonas (AM)</option>
                          <option value="BA">Bahia (BA)</option>
                          <option value="CE">Ceará (CE)</option>
                          <option value="DF">Distrito Federal (DF)</option>
                          <option value="ES">Espírito Santo (ES)</option>
                          <option value="GO">Goiás (GO)</option>
                          <option value="MA">Maranhão (MA)</option>
                          <option value="MT">Mato Grosso (MT)</option>
                          <option value="MS">Mato Grosso do Sul (MS)</option>
                          <option value="MG">Minas Gerais (MG)</option>
                          <option value="PA">Pará (PA)</option>
                          <option value="PB">Paraíba (PB)</option>
                          <option value="PR">Paraná (PR)</option>
                          <option value="PE">Pernambuco (PE)</option>
                          <option value="PI">Piauí (PI)</option>
                          <option value="RJ">Rio de Janeiro (RJ)</option>
                          <option value="RN">Rio Grande do Norte (RN)</option>
                          <option value="RS">Rio Grande do Sul (RS)</option>
                          <option value="RO">Rondônia (RO)</option>
                          <option value="RR">Roraima (RR)</option>
                          <option value="SC">Santa Catarina (SC)</option>
                          <option value="SP">São Paulo (SP)</option>
                          <option value="SE">Sergipe (SE)</option>
                          <option value="TO">Tocantins (TO)</option>
                        </select>
                      </div>
                      <div
                        style={{
                          fontSize: 14
                        }}
                        className="form-outline form-black mb-4"
                      >
                        <Select
                          style={{ height: 20 }}
                          className="select-component"
                          value={city}
                          onChange={setCity}
                          options={cities}
                          placeholder="Cidade"
                        />
                      </div>
                      <div className="form-outline form-black mb-4 eye">
                        <input
                          autoComplete="on"
                          type={passwordVisible ? "text" : "password"}
                          id="typePasswordX"
                          placeholder="senha"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control form-control-lg"
                        />
                        <div className="eye-img">
                          <img
                            alt={
                              passwordVisible
                                ? "olho aberto"
                                : "olho olho fechado"
                            }
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            src={passwordVisible ? EyeInvisible : EyeVisible}
                          />
                        </div>
                      </div>
                      <div className="form-outline form-black mb-4 eye">
                        <input
                          autoComplete="on"
                          type={passwordVisible ? "text" : "password"}
                          id="typePasswordAgainX"
                          placeholder="repetir senha"
                          className="form-control form-control-lg"
                          value={passwordRepeat}
                          onChange={(e) => setPasswordRepeat(e.target.value)}
                        />
                        <div className="eye-img">
                          <img
                            alt={
                              passwordVisible
                                ? "olho aberto"
                                : "olho olho fechado"
                            }
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            src={passwordVisible ? EyeInvisible : EyeVisible}
                          />
                        </div>
                      </div>
                      {errorStatus !== null && (
                        <p className="error">{errorStatus}</p>
                      )}
                      <p className="mb-3 pb-lg-2 info-register">
                        <span>FIQUE LIGADO!</span> As notificações de novas
                        pesquisas serão enviadas por email ou SMS{" "}
                      </p>
                      <button className="btn btn-send btn-lg px-5">
                        Cadastrar
                      </button>
                      <div className="registration-div">
                        <p className="mt-4 mb-0">
                          Já possui cadastro?{" "}
                          <Link to="/login" className="text-black-50 fw-bold">
                            Clique Aqui
                          </Link>
                        </p>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
