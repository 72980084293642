import Axios from "axios";

export default class dynamicLinkService {
  static createDynamicLink = async (data) => {
    let response = null;
    let dataToRequest = null;
    let endpointAPI = null;

    if (data.survey_ia === true) {
      dataToRequest = JSON.stringify({
        controller: data.token,
        linkSurvey: data.linkSurvey
      });
      endpointAPI = `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/chatbot/dynamic_link/`;
    } else if (data.survey_ia === false) {
      dataToRequest = JSON.stringify({
        token: data.token,
        linkSurvey: data.linkSurvey
      });
      endpointAPI = `${process.env.REACT_APP_BASE_URL_API_HAZO_APP}v2/dynamic_link/`;
    }

    await Axios.post(endpointAPI, dataToRequest)
      .then((dynamicLink) => {
        response = dynamicLink.data.data;
      })
      .catch(() => {
        response = false;
      });
    return response;
  };
}
