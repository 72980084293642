import React from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

export const Conditions = () => {
  return (
    <section className="gradient-custom py-3 px-3 py-md-5 px-md-5">
      <div className="col-12 col-lg-10" style={{ margin: "0 auto" }}>
        <Link
          to="/"
          style={{ color: "#fff" }}
          className="d-flex align-items-center"
          data-testid="anchor"
        >
          <BiArrowBack
            style={{ height: 40, width: 40 }}
            data-testid="arrow-left"
          />
        </Link>
        <div
          className="card bg-light text-black px-4 pb-3"
          style={{ borderRadius: 10, fontSize: 14 }}
        >
          <h2 className="mb-2 text-center py-4">Termos e Condições</h2>
          <p>
            1.- Ao se cadastrar nesta plataforma, o entrevistado está ciente de
            que está entregando seus dados pessoais para que possam ser colhidas
            as informações para as estatísticas das pesquisas de mercado das
            quais participará como entrevistado.{" "}
          </p>
          <p>
            1.1.-
            <strong>
              Os dados pessoais do entrevistado não serão, de nenhuma forma,
              compartilhados
            </strong>
            . Os dados serão tratados apenas internamente para a apuração de
            estatísticas das pesquisas de mercado das quais o entrevistado
            participar. Entretanto, o entrevistado reconhece e concorda que seu
            nome e número de CPF seja enviado para o instituto ao qual será
            formalizada a doação em razão das pontuações atingidas pela
            inscrição e pela participação de pesquisas, reconhecendo que a
            remessa de tais dados não representa qualquer violação à LGPD
          </p>
          <p>
            1.2.- As respostas entregues pelo entrevistado à Hazo Rewards serão
            anônimas, de modo que não será possível correlacionar a resposta aos
            dados pessoais do entrevistado. Serão atreladas às respostas apenas
            os dados para fins de estatística que não possam identificar a
            pessoa, como, por exemplo, sexo, idade, profissão.
          </p>
          <p>
            1.3.- O entrevistado concorda com o recebimento de e-mails, SMS e
            mensagens por eventuais aplicativos, informando-o de novas pesquisas
            das quais o entrevistado possa participar. Caso queira a sua
            exclusão da plataforma para não mais receber informativos, bastará
            que o entrevistado envie e-mail para <u>121labs@121labs.io</u>{" "}
            solicitando a sua exclusão do quadro de participantes de pesquisas
            de mercado.
          </p>
          <p>
            2.- O entrevistado se compromete a responder aos questionários das
            pesquisas em que participar com boa-fé e sinceridade, de modo a
            garantir a autenticidade do resultado das pesquisas.
          </p>
          <p>
            2.1.- O entrevistado reconhece que não há nenhum vínculo entre as
            partes, nem mesmo vínculo empregatício, pois o cadastramento e
            participação do entrevistado nas pesquisas desta plataforma
            consistem em mera manifestação de vontade própria do entrevistado.
          </p>
          <p>
            3.- O entrevistado reconhece, por fim, que o pagamento da sua
            premiação por institutos, conforme previsto no item 1.1.- acima,
            somente acontecerá quando o entrevistado atingir o valor acumulado
            de, no mínimo, R$40,00 (quarenta reais) em seu cadastro,
            reconhecendo, ainda, que o seu cadastro na plataforma e a sua
            participação em pesquisas gerará pontuações variáveis, a critério da
            Hazo Rewards, de acordo com a finalidade e segmento de cada
            pesquisa.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Conditions;
