import Axios from "axios";

export default class userProfileService {
  static createUserProfile = async (userData) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user_profile/`,
      data: JSON.stringify({
        user: userData.email,
        arrayDescription: userData.arrayDescription
      })
    })
      .then(() => {
        response = true;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getUser = async (email) => {
    let response = null;
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user/?user=${email}`
    )
      .then((user) => {
        response = user.data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getUserProfile = async (email) => {
    let response = null;

    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/user_profile/?user=${email}`
    )
      .then((user) => {
        response = user.data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
