import React from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
// import { FcGoogle } from "react-icons/fc";
// import { BsFacebook } from "react-icons/bs";
import authService from "../../services/authService";
import emailMktService from "../../services/emailMarketingService";
import eyeVisible from "../../assets/img/ICONEOLHOHAZO.png";
import eyeInvisible from "../../assets/img/ICONEOLHOHAZOFECHADO.png";
import hazoHeroi2 from "../../assets/img/heroi-normal-com-fundo.png";
import Loading from "../../components/Loading";
import userService from "../../services/userService";

const SignIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  //eslint-disable-next-line
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit() {
    setErrorStatus(null);

    const isValidEmail = new RegExp(
      //eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
    ).test(email);

    if (email.length === 0 || password.length === 0) {
      return setErrorStatus("Por favor, insira o email e a senha");
    } else if (email.length !== 0 && !isValidEmail) {
      return setErrorStatus("Email inválido");
    }

    let authResult;
    try {
      authResult = await authService.signIn({ email, password });
    } catch (error) {
      // Handle any errors that may occur during the signIn process
      // console.error("Error during sign in:", error);
      return setErrorStatus("Ocorreu um erro durante o login.");
    }

    const { response, errorMessage } = authResult || {};

    // handle error from login
    if (errorMessage) {
      // Users than send a request for delete account
      if (errorMessage.match(/auth\/user-disabled/gi)) {
        setErrorStatus("Usuário desabiltado ou não encontrado");
      } else if (errorMessage.match(/auth\/user-not-found/gi)) {
        setErrorStatus("Usuário não cadastrado");
      } else if (errorMessage.match(/auth\/wrong-password/gi)) {
        setErrorStatus("Usuário ou senha incorreto.");
      } else if (errorMessage.match(/auth\/too-many-requests/gi)) {
        setErrorStatus(
          "Voce fez muitas tentativas com a senha incorreta. Por favor, clique em 'Esqueceu a senha' para restaurar sua senha."
        );
      }
    }

    // handle success from login
    if (response) {
      setLoading(true);
      if (response._delegate.emailVerified) {
        let responseUpdateUser = await userService.updateUser({
          user: email,
          email_verified: "True"
        });
        if (responseUpdateUser === "User has already been updated.") {
          setLoading(false);
          navigate("/dashboard");
        } else if (responseUpdateUser === "User was updated.") {
          emailMktService.sendEmailMarketingWelcome(email);
          setLoading(false);
          navigate("/dashboard");
        } else if (responseUpdateUser === "An error from database happend.") {
          setLoading(false);
          setErrorStatus("Ocorreu um erro, recarregue a página novamente.");
        } else {
          setLoading(false);
          setErrorStatus("Um erro aconteceu, tente novamente mais tarde.");
        }
      } else if (response._delegate.emailVerified === false) {
        setLoading(false);
        authService.sendEmailVerification();
        navigate("/emailvalidation", {
          state: {
            resendEmailValidation: true
          }
        });
      }
    }
  }

  if (loading) {
    return <Loading />;
  } else {
    return (
      <section className="gradient-custom">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card bg-light text-black"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 text-center">
                  <div className="mb-md-5 mt-md-4 pb-3">
                    <h2 className="mb-2">Login</h2>
                    <img
                      className="card-img-top img-heroi"
                      src={hazoHeroi2}
                      alt="Hazo Heroi 2"
                    />
                    <p className="text-black-50 mb-5">
                      Por favor, informe seu email e senha!
                    </p>
                    <div className="form form-login">
                      <div className="form-outline form-black mb-4">
                        <input
                          autoComplete="on"
                          type="email"
                          id="typeEmailX"
                          placeholder="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control form-control-lg"
                          onKeyDownCapture={(event) => {
                            if (event.key === "Enter") {
                              handleSubmit();
                            }
                          }}
                        />
                      </div>
                      <div className="form-outline form-black mb-4 eye">
                        <input
                          autoComplete="on"
                          type={passwordVisible ? "text" : "password"}
                          id="typePasswordX"
                          placeholder="senha"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control form-control-lg"
                          onKeyDownCapture={(event) => {
                            if (event.key === "Enter") {
                              handleSubmit();
                            }
                          }}
                        />
                        <div className="eye-img">
                          <img
                            alt={
                              passwordVisible
                                ? "olho aberto"
                                : "olho olho fechado"
                            }
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            src={passwordVisible ? eyeInvisible : eyeVisible}
                          />
                        </div>
                      </div>
                      <p className="small mb-5 pb-lg-2">
                        <Link className="forgot-password" to="/forgotpassword">
                          Esqueceu a senha?
                        </Link>
                      </p>
                      {errorStatus !== null && (
                        <p className="error">{errorStatus}</p>
                      )}
                      <button
                        className="btn btn-send btn-lg px-5"
                        onClick={handleSubmit}
                      >
                        Entrar
                      </button>
                      <div className="registration-div">
                        <p className="mt-4 mb-0 fs-sm-6">
                          Não possui cadastro?{" "}
                          <Link to="/signup" className="text-black-50 fw-bold">
                            Clique Aqui
                          </Link>
                        </p>
                      </div>{" "}
                    </div>
                  </div>

                  {/* <p className="mt-4 mb-2">Ou</p> */}

                  {/* <div className="d-flex justify-content-center text-center">
                  <a href="#!" className="gl-svg">
                    <FcGoogle className="fab fa-lg" />
                  </a>
                  <a href="#!" className="fc-svg">
                    <BsFacebook className="fab fa-lg" />
                  </a>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default SignIn;
