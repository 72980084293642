import { firebaseAuth } from "../config/firebase";
export const Auth = firebaseAuth;

export default class authService {
  static sendEmailVerification = () => {
    firebaseAuth.currentUser.sendEmailVerification();
  };

  static getUserAuth = async () => {
    return firebaseAuth.currentUser.email;
  };

  static signUp = async (userData) => {
    let response = null;
    let errorMessage = null;
    await firebaseAuth
      .createUserWithEmailAndPassword(userData.email, userData.password)
      .then(() => {
        response = true;
      })
      .catch((error) => {
        errorMessage = error.message;
      });
    return { response, errorMessage };
  };

  static signIn = async (userData) => {
    let response = null;
    let errorMessage = null;
    await firebaseAuth
      .signInWithEmailAndPassword(userData.email, userData.password)
      .then((objectAuth) => {
        response = objectAuth.user;
      })
      .catch((error) => {
        errorMessage = error.message;
      });
    return { response, errorMessage };
  };

  static signOut = () => {
    firebaseAuth.signOut();
  };

  static resetPassword = async (userData) => {
    const user = firebaseAuth.currentUser;
    const credential = firebaseAuth.EmailAuthProvider.credential(
      userData.email,
      userData.password
    );
    let status = {};
    await user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        await user
          .updatePassword(userData.newPassword)
          .then(async () => {
            status = {
              message: "Senha alterada com sucesso!",
              type: "success"
            };
          })
          .catch(() => {
            status = {
              message: "Não foi possível alterar a senha!",
              type: "fail"
            };
          });
      })
      .catch(() => {
        status = {
          message: "Senha atual incorreta!",
          type: "fail"
        };
      });

    return status;
  };

  static sendEmailToResetPassword = async (email) => {
    let status = {};

    await firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        status = {
          message: "Resent",
          code: "success"
        };
      })
      .catch((error) => {
        status = {
          message: error.message,
          code: error.code
        };
      });

    return status;
  };

  static deleteUserWithoutLogin = async (email, password) => {
    let response = null;
    let errorMessage = null;

    // Is a rule of firebase to delete a user from firebase Auth login is necessary
    // Acording with firebasea best way to delete a user without login are create a cloud function or another similar services
    await firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(async (objectAuth) => {
        await objectAuth.user
          .delete()
          .then(() => {
            response = true;
          })
          .catch((error) => {
            errorMessage = error;
          });
      })
      .catch((error) => {
        errorMessage = error;
      });

    return { response, errorMessage };
  };
}
