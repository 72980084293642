import Axios from "axios";

export default class surveyCompletedService {
  static updateSurveyCompleted = async (data) => {
    let response = null;
    let dataToRequest = JSON.stringify({
      user: data.user,
      linkSurvey: data.linkSurvey
    });
    await Axios.put(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/survey_completed/index.php`,
      dataToRequest
    )
      .then(() => {
        response = true;
      })
      .catch(() => {
        response = false;
      });
    return response;
  };
}
