import Axios from "axios";

export default class surveyQualifiedService {
  static getSurveyQualified = async (email) => {
    let response = null;
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL_API_HAZO_REWARDS}v1/survey_qualified/?user=${email}`
    )
      .then((surveys) => {
        response =
          typeof surveys.data.data === "string" ? [] : surveys.data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
