import React from "react";
import { Link, useNavigate } from "react-router-dom";
import hazonormal from "../../assets/img/heroi-com-fundo.png";
import Balloon from "../../assets/img/balloon.png";
import "./style.scss";

const Presentation = () => {
  let navigate = useNavigate();

  return (
    <section className="gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card bg-light text-black"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 px-4 text-center">
                <div
                  className="mb-md-4 mt-md-4"
                  style={{ position: "relative" }}
                >
                  <h1 className="mb-2 logo">Hazo Rewards</h1>
                  <div className="balloon-container">
                    <img
                      className="card-img-top hazo-heroi mb-4"
                      src={hazonormal}
                      alt="camaleão hazo"
                    />
                    <div className="text-balloon">
                      <img
                        className="card-img-top img-hazo mb-4"
                        src={Balloon}
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="pix-line">Aceita Pix</p>
                      <p className="opinion-line">pela sua opinião?</p>
                    </div>
                  </div>
                </div>
                <div className="text-container">
                  <h3>Ganhe Dinheiro</h3>
                  <h4>respondendo pesquisas!</h4>
                  <p>
                    Com <b>Hazo Rewards</b> a sua opinião vale muito!
                  </p>
                  <p>
                    São mais de <b>5 milhões</b> de opiniões que ajudaram as
                    empresas a crescer!
                  </p>
                  <span>
                    E você também pode ajudar,{" "}
                    <b>ganhando dinheiro com isso!</b>
                  </span>
                </div>
                <div className="bottom-card">
                  <button
                    onClick={() => navigate("/signup")}
                    className="btn btn-send btn-lg px-5 btn-cadastro"
                  >
                    Ganhe R$1,00 para Cadastrar
                  </button>
                  <p className="fw-10">
                    Ao cadastrar você concorda com os Termos e Condições
                  </p>
                  <p>
                    <Link
                      to="/termsandconditions"
                      className="text-black-50 fw-bold"
                    >
                      Termos e Condições
                    </Link>
                  </p>
                  <span>
                    Já possui Cadastro?{" "}
                    <Link className="text-black-50 fw-bold" to="/login">
                      Clique Aqui
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
