import "./style.scss";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userContext } from "../../context/User";
import { BiDonateHeart } from "react-icons/bi";
import { BsPiggyBank } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import hazoHeroi2 from "../../assets/img/heroi-normal-com-fundo.png";
import RescueSection from "../../components/RescueSection";
import userWalletService from "../../services/userWalletService";

const Wallet = () => {
  const { user } = useContext(userContext);
  const [selectTypeChecked, setSelectTypeChecked] = useState("reward");
  const [valuePaymentReward, setvaluePaymentReward] = useState(null);
  const [valuePaymentDonation, setvaluePaymentDonation] = useState(null);
  const navigator = useNavigate();

  useEffect(() => {
    async function getWallet() {
      let response = await userWalletService.getUserWallet(user.email);
      if (response.value_discharge < 40) {
        navigator("/dashboard");
      } else {
        setvaluePaymentReward(response.value_discharge);
        setvaluePaymentDonation(response.value_donation);
      }
    }

    if (valuePaymentReward === null) {
      getWallet();
    }
  }, [user, valuePaymentReward, navigator]);

  if (valuePaymentReward >= 40.0) {
    return (
      <section className="gradient-custom">
        <div className="container py-2 h-100 text-center">
          <div className="col-12 col-lg-10 my-2 mx-auto">
            <div
              className="card bg-light text-black px-2"
              style={{ borderRadius: 10 }}
            >
              <Link className="btn-back" to="/dashboard">
                <FaArrowLeft />
              </Link>

              <div className="justify-content-space">
                <h1 className="logo-header">Hazo Rewards</h1>
              </div>
              <h2 className="my-3">Resgate</h2>
              <div className="row cards">
                <div className="content col-sm-12 col-md-10 com-xl-8 mx-auto">
                  <div className="research">
                    <div>
                      <h5>Carteira</h5>
                      <h3>Valor de Recompensa: R$ {valuePaymentReward}</h3>
                    </div>
                    <p>Valor de Doação: R$ {valuePaymentDonation}</p>
                  </div>
                  <div className="card py-3 my-3 position-relative">
                    <h4 className="card-title mt-4">
                      Valor para Resgate ou Doação: R$ {valuePaymentReward}
                    </h4>
                    <div className="card-body py-0 px-3 card-body-wallet">
                      <div className="radio-div">
                        <label>
                          <input
                            name="wallet choice"
                            value="reward"
                            defaultChecked
                            type="radio"
                            onChange={(e) =>
                              setSelectTypeChecked(e.target.value)
                            }
                          />{" "}
                          <BsPiggyBank /> Pix na minha conta
                        </label>
                        <label className="pt-2 pt-sm-0">
                          <input
                            name="wallet choice"
                            onChange={(e) =>
                              setSelectTypeChecked(e.target.value)
                            }
                            value="donation"
                            type="radio"
                          />
                          <BiDonateHeart /> Doar Recompensa
                        </label>
                      </div>
                      <RescueSection
                        option={selectTypeChecked}
                        valuePaymentReward={valuePaymentReward}
                        valuePaymentDonation={valuePaymentDonation}
                      />
                    </div>
                  </div>
                  <p className="p-observation">
                    **Ao solicitar o Pix na sua conta ou doar o seu saldo, o
                    valor do Saldo Doação será transferido para a Instituição.
                  </p>
                  <img
                    className="img-content img-heroi"
                    src={hazoHeroi2}
                    alt="camaleão hazo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default Wallet;
